import request from '@/utils/request.js'

// 账户登录
export function getLogin(data) {
  return request({
    url: '/api/v1.user/login',
    method: 'POST',
    data
  })
}

// 注册账号-国内注册
export function getRegister(data) {
  return request({
    url: '/api/v1.user/register',
    method: 'POST',
    data
  })
}

// 发送短信
export function getSendSms(data) {
  return request({
    url: '/api/v1.other/sendSms',
    method: 'POST',
    data
  })
}

// 找回密码-手机号找回
export function getResetpwd(data) {
  return request({
    url: '/api/v1.user/resetpwd',
    method: 'POST',
    data
  })
}

// 手机验证码登录
export function getMobilelogin(data) {
  return request({
    url: '/api/v1.user/mobilelogin',
    method: 'POST',
    data
  })
}

// 获取图形验证码
// export function getCaptcha(params) {
//   return request({
//     url: '/api/v1.common/captcha',
//     method: 'GET',
//     params
//   })
// }
