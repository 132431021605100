<!-- 协议 -->
<template>
  <div>
    <div class="agreement">
      <el-dialog
        title="知识付费用户协议"
        :visible.sync="centerDialogVisible"
        width="56%"
        center
        custom-class="agree-dialog"
      >
        <span>
          <h2>用户注册协议</h2>
          <h3>一、注册协议条款的确认和接受</h3>
          <p v-for="item_p in 10" :key="item_p">
            为获得网络服务, 申请人应当认真阅读、充分理解本《协议》中各条款,
            包括免除或者限制文都责任的免责条款及对用户的权利限制条款。审慎阅读并选择接受或不接受本《协议》(未成年人应在法定监护人陪同下阅读)。
          </p>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement',
  data() {
    return {
      centerDialogVisible: false
    }
  },
  created() {},
  methods: {
    agreement() {
      this.centerDialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
@mixin h_span {
  margin: 0px;
  border: 0px none;
  list-style: none;
  color: rgb(51, 51, 51);
  font-family: 'Microsoft Yahei';
  white-space: normal;
}

.agreement {
  .agree-dialog .el-button {
    padding: 14px 73px;
    border-color: #2c8eff;
    border-radius: 23px;
    background-color: #2c8eff;
    font-size: 16px;
    color: #fff;
  }

  ::v-deep {
    .el-dialog__headerbtn {
      font-size: 20px;
    }

    .el-dialog__header,
    .el-dialog__footer {
      box-shadow: 0 0 12px 0 hsl(0deg 0% 85% / 35%);
    }

    .el-dialog__title {
      font-weight: bold;
      font-size: 20px;
      color: #333;
    }

    .el-dialog__body {
      max-height: 400px;
      overflow: auto;
      padding-right: 30px;
      padding-bottom: 10px;
      padding-left: 30px;

      &::-webkit-scrollbar {
        width: 10px;
      }
      // 滚动槽
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #f2f2f2;
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      // 滚动滑块
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #b4bbc5;
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      span {
        h2 {
          padding: 16px 0px 6px;
          font-size: 16px;
          text-align: center;
          @include h_span();
        }

        h3 {
          font-size: 14px;
          padding: 5px 0px;
          @include h_span();
        }

        p {
          padding: 5px 0px;
          color: rgb(85, 85, 85);
          text-indent: 2em;
          font-size: 12px;
          @include h_span();
        }
      }
    }
  }
}
</style>
