import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCarousel from 'vue-carousel'
// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/icons/iconfont.css'
Vue.use(ElementUI)

Vue.use(VueCarousel);


// 全局样式引入
import '@/assets/styles/global.scss'
// 局部样式
import '@/assets/styles/index.scss'

/* 视频播放-video-player 组件*/
import VideoPlayer from 'vue-video-player'
// import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

/* 富文本编辑框 */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import quillEditor from 'vue-quill-editor'
Vue.use(quillEditor)

/* swiper 插件 */
// 引入 vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
//引入 vue-awesome-swiper 样式
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */)
// swiper
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
Vue.prototype.$swiper = Swiper

/* wowJS 动画插件 */
import 'wowjs/css/libs/animate.css'
import 'animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow

/**
 // 全局mixin:
 * 1. 路由跳转重新刷新页面
 * 2.
 */
Vue.mixin({
  methods: {
    refreshT() {
      location.reload()
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
