/* 一级页面路由 */

let stairRouter = [
  {
    path: '/exam_practice/CFA/CFAnalysis',
    name: 'CFAnalysis',
    meta: { title: 'CFAnalysis' },
    component: () => import('@/views/exam_practice/CFA/CFAnalysis.vue')
  },
  {
    path: '/exam_practice/CFA/Keycode',
    name: 'Keycode',
    meta: { title: '题库识别码' },
    component: () => import('@/views/exam_practice/CFA/Keycode.vue')
  },
  {
    path: '/exam_practice/CFA/Online_Tutorial',
    name: 'online_Tutorial',
    meta: { title: 'CFAonline_Tutorial使用教程' },
    component: () => import('@/views/exam_practice/CFA/Online_Tutorial.vue')
  },
  {
    path: '/exam_practice/CFA',
    name: 'CFApractice',
    meta: { title: 'CFApractice题库页' },
    component: () => import('@/views/exam_practice/CFA/index.vue')
  },
  {
    path: '/exam_practice/Acca',
    name: 'AccaPage',
    meta: { title: 'AccaPage' },
    component: () => import('@/views/exam_practice/ACCA/index.vue')
  },
  {
    path: '/exam_practice/common/Cpa_examtest_jk',
    name: 'Cpa_examtest_jk',
    meta: { title: 'Cpa_examtest_jk机考做题页' },
    component: () =>
      import('@/views/exam_practice/CPA/common/Cpa_examtest_jk.vue')
  },
  {
    path: '/exam_practice/CMA',
    name: 'CMAPage',
    meta: { title: 'CMAPage' },
    component: () => import('@/views/exam_practice/CMA/index.vue')
  },
  {
    path: '/lineCourseList/course_play_cc2',
    name: 'course_play_cc2',
    meta: { title: 'course_play_cc2长线课程视频观看' },
    component: () => import('@/views/lineCourseList/common/course_play_cc2.vue')
  },
  {
    path: '/newzhike/tutorial',
    name: 'tutorial',
    meta: { title: 'tutorial模拟&机考测试教程' },
    component: () => import('@/views/newzhike/mockList/tutorial.vue')
  },
  {
    path: '/newzhike/mockExam',
    name: 'mockExam',
    meta: { title: 'mockExam模拟&机考做题' },
    component: () => import('@/views/newzhike/mockList/mockExam.vue')
  },
  {
    path: '/newzhike/FRM/FrmockExam',
    name: 'FrmockExam',
    meta: { title: 'FrmockExam-FRM模拟&机考做题' },
    component: () => import('@/views/newzhike/mockList/FRM/FrmockExam.vue')
  },
  {
    path: '/newzhike/playback',
    name: 'playback',
    meta: { title: 'playback' },
    component: () => import('@/views/newzhike/studyPlan/playback.vue')
  }
]

export default {
  stairRouter
}
