<template>
  <div class="login_form">
    <ul class="nav_tabs">
      <li>
        <span>注册账号</span>
      </li>
    </ul>

    <div class="tab_main">
      <!-- 注册账户 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo_ruleForm"
      >
        <el-form-item prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            prefix-icon="el-icon-s-custom"
            placeholder="请输入您的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="codeNote" class="captcha_t">
          <el-input placeholder="请输入验证码" v-model="ruleForm.codeNote">
          </el-input>
          <el-button
            type="primary"
            size="small"
            round
            @click="sendCode(ruleForm)"
            :disabled="counter > 0 ? true : false"
          >
            {{
              counter > 0
                ? `${counter < 10 ? '0' : ''}${counter} 秒后重新发送`
                : '发送验证码'
            }}
          </el-button>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            placeholder="请输入新密码"
            v-model="ruleForm.newPassword"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="oldPassword">
          <el-input
            placeholder="请确认您的密码"
            v-model="ruleForm.oldPassword"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>

      <el-button
        type="primary"
        size="medium"
        round
        style="width: 100%"
        @click="registerNow(ruleForm)"
      >
        立即注册
      </el-button>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 15px;
        "
      >
        已注册？
        <span style="color: #388eff; cursor: pointer" @click="register">
          <!-- <a :href="`#/login/login`"> 去登录 </a> -->
          <a href="javascript:void(0);"> 去登录 </a>
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="content">
    <div class="login_box">
      <div class="login_left"></div>
      <div class="login_right">
      </div>
    </div>
  </div> -->
</template>

<script>
import { getRegister, getSendSms } from '@/api/login.js'

export default {
  name: 'register',
  data() {
    return {
      ruleForm: {
        mobile: '',
        newPassword: '',
        oldPassword: '',
        codeNote: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9]{6,15}$/,
            message: '密码需要6-15个字符',
            trigger: 'blur'
          }
        ],
        oldPassword: [
          { required: true, message: '请确认你的密码', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9]{6,15}$/,
            message: '密码需要6-15个字符',
            trigger: 'blur'
          }
        ],
        codeNote: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            pattern: /^\d{6}$/,
            message: '验证码不正确',
            trigger: 'blur'
          }
        ]
      },
      counter: 0
    }
  },
  created() {},
  methods: {
    registerNow(rulVal) {
      this.$refs.ruleForm.validate((valid, valMsg) => {
        const passwordRegex = /^[a-zA-Z0-9]{6,15}$/
        if (!valid) {
          return
        }
        if (!passwordRegex.test(rulVal.newPassword)) {
          if (!rulVal.newPassword) {
            this.$message.warning({
              type: 'warning',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--warning',
              message: '新密码不能为空'
            })
          }
          return
        } else if (rulVal.newPassword !== rulVal.oldPassword) {
          this.$message.warning({
            type: 'warning',
            iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
            customClass: 'el-message--warning',
            message: '请确认密码输入是否一致'
          })
          return
        } else {
          let register_params = {
            type: 1, // 1 国内 2国外
            password: rulVal.newPassword,
            code: rulVal.codeNote,
            mobile: rulVal.mobile
          }
          getRegister(register_params).then(({ data: data }) => {
            console.log(register_params, '55密码校验通过55', data)
            this.$message.success({
              type: 'success',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--success',
              message: data.msg
            })
          })
        }
      })
    },
    sendCode(rulValue) {
      let mobileResX = /^1[3-9]\d{9}$/
      if (rulValue.mobile) {
        if (mobileResX.test(rulValue.mobile)) {
          this.counter = 60
          let timer = setInterval(() => {
            this.counter--
            if (this.counter <= 0) {
              clearInterval(timer)
              this.counter = 0
            }
          }, 1000)

          let send = {
            event: 'register',
            mobile: rulValue.mobile
          }
          getSendSms(send).then(({ data: data }) => {
            console.log(send, '校验通过', data)
            this.$message.success({
              type: 'success',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--success',
              message: data.msg
            })
          })
        } else {
          this.$message.warning({
            type: 'warning',
            iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
            customClass: 'el-message--warning',
            message: '请输入正确的手机号'
          })
        }
      } else {
        this.$message.warning({
          type: 'warning',
          iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
          customClass: 'el-message--warning',
          message: '手机号不能为空'
        })
        return
      }
    },
    register() {
      this.$parent.loginShow = 1
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/loginCommon.scss';

.login_box {
  .login_right {
    .login_form {
      .nav_tabs {
        display: flex;
        justify-content: center;
        text-align: center;

        li {
          width: 40%;
          padding: 0px 0;
          font-size: 17px;
          cursor: pointer;
          font-weight: bold;

          span {
            color: #333;
            display: block;
            height: 45px;
            line-height: 45px;
          }
        }
      }
    }
  }
}

// .content {
//   position: relative;
//   width: 100vw;
//   height: 100vh;
// }
// .login_box {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   width: 950px;
//   height: 500px;
//   border-radius: 8px;
//   overflow: hidden;
//   display: flex;
//   box-shadow: 0px 0px 5px #777;
//   .login_left {
//     padding: 20px;
//     width: 475px;
//     height: 500px;
//     background: #74b9ff;
//     box-sizing: border-box;
//   }

//   .login_right {
//     position: relative;
//     width: 475px;
//     height: 500px;
//     background: url('@/assets/images/ybbg.jpg') no-repeat center center;

//     .login_form {
//       padding: 10px 32px;
//       width: 350px;
//       height: 440px;
//       background: #ffffff;
//       box-shadow: 0 0 8px #cccccc;
//       border-radius: 8px;
//       position: absolute;
//       box-sizing: border-box;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);

//       .nav_tabs {
//         display: flex;
//         justify-content: center;
//         text-align: center;

//         li {
//           width: 40%;
//           padding: 0px 0;
//           font-size: 17px;
//           cursor: pointer;
//           font-weight: bold;

//           span {
//             color: #333;
//             display: block;
//             height: 45px;
//             line-height: 45px;
//           }
//         }
//       }

//       .tab_main {
//         height: 360px;

//         .demo_ruleForm {
//           padding-top: 24px;

//           .captcha_t {
//             ::v-deep {
//               .el-form-item__content {
//                 display: flex;
//                 align-items: center;
//                 justify-content: space-between;
//               }

//               .el-button--small.is-round {
//                 padding: 11px 15px;
//               }
//             }
//           }

//           ::v-deep {
//             .el-button.is-disabled,
//             .el-button.is-disabled:hover {
//               cursor: auto;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
