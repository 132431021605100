/* zhike模块路由 */

let zhike = [
  {
    path: 'studyPlan',
    name: 'studyPlan',
    meta: { title: 'studyPlan学习计划' },
    component: () => import('@/views/newzhike/common/studyPlan.vue')
  },
  {
    path: 'dataDownload',
    name: 'dataDownload',
    meta: { title: 'dataDownload资料下载' },
    component: () => import('@/views/newzhike/common/dataDownload.vue')
  },
  {
    path: 'studyReport',
    name: 'studyReport',
    meta: { title: 'studyReport学习报告' },
    component: () => import('@/views/newzhike/common/studyReport.vue')
  },
  {
    path: 'answerQuestion',
    name: 'answerQuestion',
    meta: { title: 'answerQuestion笔记答疑' },
    component: () => import('@/views/newzhike/answerQuestion/index.vue')
  },
  {
    path: 'answerQuestionlist',
    name: 'answerQuestionlist',
    meta: { title: 'answerQuestionlist笔记答疑详情' },
    component: () =>
      import('@/views/newzhike/answerQuestion/answerQuestionlist.vue')
  },
  {
    path: 'answerQuestion_detail',
    name: 'answerQuestion_detail',
    meta: { title: 'answerQuestion_detail答疑列表详情' },
    component: () =>
      import('@/views/newzhike/answerQuestion/answerQuestion_detail.vue')
  },
  {
    path: 'courseOutline',
    name: 'courseOutline',
    meta: { title: 'courseOutline课程大纲' },
    component: () => import('@/views/newzhike/courseOutline/index.vue')
  },
  {
    path: 'courseOutlineTest',
    name: 'courseOutlineTest',
    meta: { title: 'courseOutlineTest课程大纲/习题' },
    component: () =>
      import('@/views/newzhike/courseOutline/courseOutlineTest.vue')
  },
  {
    path: 'recorded',
    name: 'recorded',
    meta: { title: 'recorded观看视频' },
    component: () => import('@/views/newzhike/courseOutline/recorded.vue')
  },
  {
    path: 'questionBank',
    name: 'questionBank',
    meta: { title: 'questionBank题库' },
    component: () => import('@/views/newzhike/questionBank/index.vue')
  },
  {
    path: 'practice',
    name: 'practice',
    meta: { title: 'practice开始做题' },
    component: () => import('@/views/newzhike/questionBank/practice.vue')
  },
  {
    path: 'testReport',
    name: 'testReport',
    meta: { title: 'testReport题库报告' },
    component: () => import('@/views/newzhike/questionBank/testReport.vue')
  },
  {
    path: 'testAnalysis',
    name: 'testAnalysis',
    meta: { title: 'testAnalysis查看解析' },
    component: () => import('@/views/newzhike/questionBank/testAnalysis.vue')
  },
  {
    path: 'collectLists',
    name: 'collectLists',
    meta: { title: 'collectLists收藏集' },
    component: () =>
      import('@/views/newzhike/questionBank/collectList/collectLists.vue')
  },
  {
    path: 'collectInfo',
    name: 'collectInfo',
    meta: { title: 'collectList收藏详情' },
    component: () =>
      import('@/views/newzhike/questionBank/collectList/collectInfo.vue')
  },
  {
    path: 'errorLists',
    name: 'errorLists',
    meta: { title: 'errorListZhike错题集' },
    component: () =>
      import('@/views/newzhike/questionBank/errorList/errorLists.vue')
  },
  {
    path: 'errorInfo',
    name: 'errorInfo',
    meta: { title: 'errorInfo错题集详情' },
    component: () =>
      import('@/views/newzhike/questionBank/errorList/errorInfo.vue')
  },
  {
    path: 'testAssessment',
    name: 'testAssessment',
    meta: { title: 'testAssessment做题评估' },
    component: () => import('@/views/newzhike/common/testAssessment.vue')
  },
  {
    path: 'mockLists',
    name: 'mockLists',
    meta: { title: 'mockList模拟&机考' },
    component: () => import('@/views/newzhike/mockList/mockLists.vue')
  },
  {
    path: 'mockReport',
    name: 'mockReport',
    meta: { title: 'mockReport-模拟&机考报告页' },
    component: () => import('@/views/newzhike/mockList/mockReport.vue')
  },
  {
    path: 'mockTestAnalysis',
    name: 'mockTestAnalysis',
    meta: { title: 'mockTestAnalysis-模拟&机考解析页' },
    component: () => import('@/views/newzhike/mockList/mockTestAnalysis.vue')
  },
  {
    path: '/newzhike/FRM/frmtestReport',
    name: 'frmtestReport',
    meta: { title: 'frmtestReport-FRM模拟&机考解析页' },
    component: () => import('@/views/newzhike/mockList/FRM/frmtestReport.vue')
  },
  {
    path: '/newzhike/FRM/frmMockAnalysis',
    name: 'frmMockAnalysis',
    meta: { title: 'frmMockAnalysis-FRM模拟&机考解析页' },
    component: () => import('@/views/newzhike/mockList/FRM/frmMockAnalysis.vue')
  },
  {
    path: 'playbackChat',
    name: 'playbackChat',
    meta: { title: 'playbackChat' },
    component: () => import('@/views/newzhike/studyPlan/playbackChat.vue')
  }
]

export default {
  zhike
}
