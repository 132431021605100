<template>
  <div class="login_form">
    <ul class="nav_tabs">
      <li :class="{ actives: actives_val == 0 }" @click="actives_val = 0">
        <span>手机号找回</span>
      </li>
      <li :class="{ actives: actives_val == 1 }" @click="actives_val = 1">
        <span>邮箱找回</span>
      </li>
    </ul>

    <div class="tab_main">
      <!-- 手机号找回 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo_ruleForm"
        v-if="actives_val == 0"
      >
        <el-form-item prop="name">
          <el-input
            v-model="ruleForm.mobile"
            prefix-icon="el-icon-s-custom"
            placeholder="请输入您的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name" class="captcha_t">
          <el-input placeholder="请输入验证码" v-model="noteForm.codeNote">
          </el-input>
          <el-button
            type="primary"
            size="small"
            round
            @click="sendCode(1)"
            :disabled="counter > 0 ? true : false"
          >
            {{
              counter > 0
                ? `${counter < 10 ? '0' : ''}${counter} 秒后重新发送`
                : '发送验证码'
            }}
          </el-button>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            placeholder="请输入您的密码"
            v-model="ruleForm.password"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            placeholder="请确认您的密码"
            v-model="ruleForm.password"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>

      <!-- 邮箱找回 -->
      <el-form
        :model="noteForm"
        :rules="noteRules"
        ref="ruleForm"
        class="demo_ruleForm"
        v-show="actives_val == 1"
      >
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model="noteForm.mobile"
            placeholder="请输入您的邮箱账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name" class="captcha_t">
          <el-input placeholder="请输入验证码" v-model="noteForm.codeNote">
          </el-input>
          <el-button
            type="primary"
            size="small"
            round
            @click="sendCode(2)"
            :disabled="youxCaptch > 0 ? true : false"
          >
            {{
              youxCaptch > 0
                ? `${youxCaptch < 10 ? '0' : ''}${youxCaptch} 秒后重新发送`
                : '发送验证码'
            }}
          </el-button>
        </el-form-item>

        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model="noteForm.mobile"
            placeholder="请输入您的密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model="noteForm.mobile"
            placeholder="请确认您的密码"
          ></el-input>
        </el-form-item>
      </el-form>

      <el-button type="primary" size="medium" round style="width: 100%">
        找回密码
      </el-button>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 15px;
        "
      >
        已有账号？
        <span style="color: #388eff; cursor: pointer" @click="forgetPass">
          <!-- <a :href="`#/login/login`"> 马上去登录 </a> -->
          <a href="javascript:void(0);"> 马上去登录 </a>
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="content">
    <div class="login_box">
      <div class="login_left"></div>
      <div class="login_right">
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'ForgetPsd',
  data() {
    return {
      ruleForm: {
        mobile: '',
        password: '',
        graphCode: ''
      },
      rules: {},
      noteForm: {
        mobile: '',
        codeNote: ''
      },
      noteRules: {},
      actives_val: 0,
      register_val: true,
      counter: 0,
      youxCaptch: 0
    }
  },
  created() {},
  methods: {
    forgetPass() {
      this.$parent.loginShow = 1
    },
    sendCode(val) {
      if (val == 1) {
        this.counter = 15
        let timer = setInterval(() => {
          this.counter--
          if (this.counter <= 0) {
            clearInterval(timer)
            this.counter = 0
          }
        }, 1000)
        return
      } else if (val == 2) {
        this.youxCaptch = 15
        let timer = setInterval(() => {
          this.youxCaptch--
          if (this.youxCaptch <= 0) {
            clearInterval(timer)
            this.youxCaptch = 0
          }
        }, 1000)
        return
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/loginCommon.scss';

/* .content {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.login_box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 950px;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 0px 5px #777;
  .login_left {
    padding: 20px;
    width: 475px;
    height: 500px;
    background: #74b9ff;
    box-sizing: border-box;
  }

  .login_right {
    position: relative;
    width: 475px;
    height: 500px;
    background: url('@/assets/images/ybbg.jpg') no-repeat center center;

    .login_form {
      padding: 10px 32px;
      width: 350px;
      height: 440px;
      background: #ffffff;
      box-shadow: 0 0 8px #cccccc;
      border-radius: 8px;
      position: absolute;
      box-sizing: border-box;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .nav_tabs {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .actives {
          color: #388eff;
          border-bottom: 4px solid #388eff;
        }

        li {
          width: 40%;
          padding: 0px 0;
          font-size: 17px;
          cursor: pointer;
          font-weight: bold;

          span {
            color: #333;
            display: block;
            height: 45px;
            line-height: 45px;
          }
        }
      }

      .tab_main {
        height: 360px;

        .demo_ruleForm {
          padding-top: 24px;

          .captcha_t {
            ::v-deep {
              .el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .el-button--small.is-round {
                padding: 11px 15px;
              }
            }
          }
        }
      }
    }
  }
} */
</style>
