import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

let lifeData = {
  vuex_token: localStorage.getItem('vuex_token')
    ? localStorage.getItem('vuex_token')
    : ''
}

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    vuex_token: lifeData.vuex_token,
    showGlobalComponent: true,
    showLoginStatus: false
  },
  getters: {},
  mutations: {
    hideGlobalComponent(state, nval) {
      state.showGlobalComponent = nval
    },
    removeToken(state) {
      state.token = undefined
    },
    setTokenAnd(state, vuex_token) {
      state.vuex_token = vuex_token
      //更新本地存储
      localStorage.setItem('vuex_token', vuex_token)
    },
    goOut(state) {
      state.vuex_token = null
      //更新本地存储
      localStorage.removeItem('vuex_token', null)
    }
  },
  actions: {},
  modules: {}
})
